import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Webcam from 'react-webcam'
import svg_bottom from './Union.svg'
import svg_top from './Union-1.svg'

import './styles.css'

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
}

function App() {
  const webcamRef = React.useRef(null)
  const [image, setImage] = useState(undefined)
  const [show, setShow] = useState(false)

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImage(imageSrc)
    console.log(imageSrc)
  }, [webcamRef])

  React.useEffect(() => {
    console.clear()
    console.log('Let me just 📸, yes very stupid.')
  }, [])

  return (
    <div>
      <Webcam
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
        }}
        audio={false}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
        onUserMedia={() => {
          setShow(true)
        }}
      />
      {show && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            justifyContent: 'space-between',
            alignItems: 'center',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <img
            src={svg_top}
            style={{ maxWidth: '900px', width: '100%', padding: '50px' }}
          />
          <img
            src={svg_bottom}
            style={{
              maxWidth: '500px',
              width: '100%',
              padding: '50px',
            }}
          />
        </div>
      )}
    </div>
  )
}

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)
